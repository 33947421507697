import {configureStore} from '@reduxjs/toolkit';
import  LogginReduces from './login';
import snackBarSlice  from './snackBar';
import customizationReducer from './customizationReducer'
export default configureStore({
    reducer : {
        logged:LogginReduces,
        snackBar:snackBarSlice,
        customization: customizationReducer
    }
})