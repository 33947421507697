import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// project imports
import NavigationScroll from "./layout/NavigationScroll";
import Routes from "./routes";
import themes from "./themes";
import SnackBar from "./components/SnackBar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Pusher from 'pusher-js';
import audioUrl  from './assets/sound/notification.mp3';
import { useState } from "react";
import { useSelector } from "react-redux";
function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  // if (!("Notification" in window)) {
  //   alert("This browser does not support desktop notification");
  // }

  // Notification.requestPermission().then(permission => {
  //   if (permission === "granted") {
  //     console.log("Notification permission granted.");
  //     // Now you can send notifications
  //   }
  // });
  const showNotification = () => {
    if (Notification.permission === "granted") {
      new Notification("Hi there!", {
        body: "This is a notification from your React app.",
        // icon: "/path/to/icon.png" // optional
      });
    }
  };
  const user = useSelector(state=>state?.logged?.me?.user_id)
  var pusher = new Pusher('20ea7f3e6a401a074bd6', {
    cluster: 'ap2'
  });
  let audioContext = null;
  let audioSource = null;
  if(user){
    var channel = pusher.subscribe('branch-order-'+user);
    channel.bind('message',async function(data) {
      try {
        // Fetch the audio file
        const response = await fetch(audioUrl);
        showNotification();
        // Convert the response to an ArrayBuffer
        const buffer = await response.arrayBuffer();
      
        // Create an AudioContext
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
      
        // Decode the audio data
        const audioBuffer = await audioContext.decodeAudioData(buffer);
      
        // Create a BufferSource node and connect it to the destination (speakers)
        audioSource = audioContext.createBufferSource();
        audioSource.buffer = audioBuffer;
        audioSource.connect(audioContext.destination);
        window.open(`${window.location.origin}/order/orderDetails/${data}`, 'TABAQ NEW ORDER-', 'status=1,toolbar=1,menubar=1,width=650,height=900');
        // Set up an event listener for when the audio ends
        audioSource.onended = () => {
          setIsPlaying(false);
        };
      
        // Start playing the audio
        audioSource.start();
      } catch (error) {
        console.error('Error loading or playing audio:', error);
      }
    });
  }

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider
          theme={themes({
            borderRadius: 12,
            defaultId: "default",
            fontFamily: "'Roboto', sans-serif",
            isOpen: [],
            opened: true,
          })}
        >
          {/* <ThemeProvider> */}
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
        <SnackBar />
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
