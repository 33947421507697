import {createSlice} from '@reduxjs/toolkit';
export const snackBarSlice = createSlice({
    name :'SnackBar',
    initialState :{
        show : false,
        variant : "",
        message : "",
        duration : ""
    },
    reducers : {
        showSnackBar:(state,action)=>{
            state.show = action.payload.show;
            state.variant = action.payload.variant;
            state.message = action.payload.message;
            state.duration = action.payload.duration||4000;
        },
        hideSnackbar:(state,action)=>{
            state.show = false;
            state.variant = '';
            state.message = '';
            state.duration = '';
        }
    }
})
export const {hideSnackbar,showSnackBar} = snackBarSlice.actions;
export default snackBarSlice.reducer;